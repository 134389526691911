import { Controller, FieldValues } from 'react-hook-form';
import Select from 'react-select';
import { IDarkSelectField } from './types';
import clsx from 'clsx';

export const DarkSelectField = <T extends FieldValues>({
  name,
  label,
  placeholder,
  disabled,
  options,
  control,
  testId,
  classname,
  labelClassname,
}: IDarkSelectField<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
        return (
          <div
            data-testid={testId}
            className={clsx('w-full flex flex-col', {
              [`${classname}`]: classname,
            })}
          >
            <label
              htmlFor={`${name}-id`}
              className={clsx(`form-label mb-[6px]`, {
                [`${labelClassname}`]: labelClassname,
              })}
            >
              {label}
            </label>
            <Select
              name={name}
              instanceId={`select-${name}`}
              inputId={`${name}-id`}
              options={options}
              onChange={onChange}
              value={value}
              placeholder={placeholder}
              isDisabled={disabled}
              onBlur={onBlur}
              isSearchable={false}
              classNames={{
                input: () => `!text-white`,
                valueContainer: () => `!pt-[8px] !pb-[12px] !px-[14px] !h-[42px] !box-border`,
                singleValue: () => `!text-white !text-sm !m-[0px]`,
                placeholder: () => `text-sm !m-[0px] !text-white50`,
                menuList: () => `bg-grey-2`,
                indicatorSeparator: () => 'hidden',
                control: ({ isFocused }) => `
                  !rounded-lg !cursor-pointer !text-white !box-border
                  !h-[42px] !transition-none !border-gray50 shadow-sm
                  ${disabled ? '!bg-black-7 opacity-[0.5]' : '!bg-white10'}
                  ${error && '!border-red-error'}
                  ${isFocused && '!shadow-light-outline !border-transparent'}
                `,
                option: ({ isSelected, isFocused }) => `
                  !cursor-pointer !text-white !text-sm  
                  ${isFocused && '!bg-white10'}
                  ${isSelected && '!bg-transparent'}
                `,
                dropdownIndicator: ({ isFocused }) => `
                  !transition-none hover:!text-white 
                  ${isFocused ? '!text-white' : '!text-white50'}
                `,
              }}
            />
            <div className="w-full min-h-[24px] flex items-center">
              {error && (
                <p
                  data-testid={`${testId}-error-message`}
                  className="w-full text-sm text-red-error text-right"
                >
                  {error.message}
                </p>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};
